import React, { useContext, useState } from 'react';
import Headroom from 'react-headroom';
import styled from '@emotion/styled';
import { Transition } from 'react-transition-group';
import { navigate, withPrefix } from 'gatsby';
import Container from 'src/components/Container';
import MobileSidebarNavigation from 'src/components/MobileSidebarNavigation';
import Progress from 'src/components/Progress';
import { RemoveScroll } from 'react-remove-scroll';
import { colors, animations, mediaQueries as mq } from 'src/styles';
import {
  NavLink,
  NavLinkMobile,
  NavLinks,
  MobileNavLinks,
  navTimeout,
} from 'src/components/NavigationStyles';
import GlobalNavigation from 'src/components/GlobalNavigation';
import Overlay from 'src/components/Overlay';
import Hamburger from 'src/components/Hamburger';
import { gsap } from 'gsap';
import PageContext from 'src/context/PageContext';
import PropTypes from 'prop-types';
import LanguageIcon from 'src/svg/language-icon.svg';
import ArrowDownIcon from 'src/svg/arrow-down.svg';
import { getSectionFromUrl } from 'src/utils/helpers';
import Link from 'src/components/Link';
import { removePrefix } from 'src/utils/helpers';

const HeaderSpacer = styled.div`
  height: 85px;
`;
const HeaderWrapper = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: ${colors.white};
  z-index: 5;
  background-color: ${colors.green};

  .headroom {
    background-color: ${colors.green};
  }
`;
const HeaderContainer = styled(Container)`
  position: relative;
  z-index: 2;
  transition: padding ${animations.mediumSpeed} ease-in-out;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  height: 85px;
`;
const LinksCol = styled.div`
  display: none;
  ${mq.largerAndUp} {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
const Desktop = styled.div`
  display: none;
  ${mq.largerAndUp} {
    display: flex;
    height: 28px;
  }
`
const Mobile = styled.div`
  display: flex;
  height: 28px;
  align-items: center;
  ${mq.largerAndUp} {
    display: none;
  }
`
const ArrowDownIconMod = styled(ArrowDownIcon)`
  margin: 2px 5px 0 5px;
`
const LanguageDropDown = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background: ${colors.white};
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  display: ${({ isOpen }) => isOpen ? 'block' : 'none'}
`
const MobileSidebar = styled.div`
  ${mq.largerAndUp} {
    display: none;
  }
`;
const LanguageSelector = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  color: ${colors.grey};
  font-weight: 700;
  font-size: 12px;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  height: 28px;
  position: relative;
`
export const LanguageName = styled.div`
  padding: ${({ padding = 10 }) => `3px ${padding}px 0px ${padding}px`};
  margin: ${({ mobile }) => mobile ? `5px 10px 5px 5px` : ''};
  cursor: pointer;
  position: relative;
  ${({ active }) => active ? `color: ${colors.green};` : ''}
  &:hover {
    color: ${colors.green};
  }
`
const Divider = styled.div`
  background: ${colors.lightGrey};
  width: 1px;
  height: 100%;
`
const HDivider = styled.div`
  background: ${colors.lightGrey};
  width: 100%;
  height: 1px;
`
const LanguageIconMod = styled(LanguageIcon)`
  margin: 0 8px;
`
const Indicator = styled.div`
  position: absolute;
  bottom: 0;
  right: ${({ right }) => right}px; //33px, 106px
  transition: all 200ms cubic-bezier(0.000, 0.000, 0.330, 0.990);
  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #2A2A2A;
  }
`
export const MobileIndicator = styled.div`
  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    right: 0px;
    bottom: calc(50% - 6px);
    border-top: 6px solid transparent;
    border-right: 6px solid ${colors.green};
    border-bottom: 6px solid transparent;
  }
`

export default function Header() {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [globalNavOpen] = useState(false);
  const { headerNavLinks, pageColors, hasHero, location, title, data, lang } =
    useContext(PageContext);

  const [languageDropDownOpen, setLanguageDropDownOpen] = useState(false);
  const getIndicatorPosition = lang => lang === 'en' ? 106 : 33;
  const indicatorPosition = getIndicatorPosition(lang)
  const secondaryLangUri = data && data.secondaryLang ? data.secondaryLang.uri.replace('/design_page', '') : (lang === 'en' ? '/es/' : '/');
  const mobileLanguage = () => {
    setLanguageDropDownOpen(v => !v)
  }

  const showNavigation = (section) => {
    setSidebarVisible(section);
  };

  const hideNavigation = () => {
    setSidebarVisible(false);
  };

  const toggleGlobalNav = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const renderNavLink = (item, index) => {
    const itemSection = getSectionFromUrl(item.url);
    const urlSection = getSectionFromUrl(location.pathname);
    const active = itemSection === urlSection;
    return (
      <NavLink
        navOpen={globalNavOpen}
        key={'navItem-' + index}
        to={item.url}
        active={active}
        target={item.target}
        rel={item.rel}
      >
        {item.linkText}
      </NavLink>
    );
  };

  const renderMobileNavLink = (item, index) => {
    return (
      <NavLinkMobile
        onClick={() => {
          location.pathname.startsWith(withPrefix(item.url))
            ? showNavigation(item.url)
            : navigate(item.url);
        }}
        key={'mobileNavItem-' + index}
        active={location.pathname.startsWith(withPrefix(item.url))}
      >
        {item.linkText}
      </NavLinkMobile>
    );
  };

  const extraLinks = [
    {
      url: lang === 'en' ? '/resources/' : '/es/recursos/',
      linkText: lang === 'en' ? 'Resources' : 'Recursos',
    },
    {
      url: 'https://xqsuperschool.org/',
      linkText: 'XQ INSTITUTE',
      target: '_self',
      rel: '',
    },
  ];

  const desktopLinksOnly = [...headerNavLinks, ...extraLinks];

  const onGlobalNavEnter = (node) => {
    gsap.to(node, {
      duration: navTimeout / 1000,
      autoAlpha: 0,
      ease: 'power2.out',
    });
  };

  const onGlobalNavExit = (node) => {
    gsap.to(node, {
      duration: navTimeout / 1000,
      autoAlpha: 1,
      delay: navTimeout / 2 / 1000,
      ease: 'power2.out',
    });
  };

  return (
    <>
      <HeaderSpacer hasHero={hasHero} />
      <HeaderWrapper navOpen={globalNavOpen} hasHero={hasHero}>
        <Headroom>
          <HeaderContainer navOpen={globalNavOpen}>
            <HeaderContent>
              <Hamburger
                title={title}
                open={sidebarVisible}
                onClick={toggleGlobalNav}
              />
              <LinksCol>
                <Transition
                  in={globalNavOpen}
                  timeout={navTimeout}
                  onEnter={onGlobalNavEnter}
                  onExit={onGlobalNavExit}
                >
                  <NavLinks>{desktopLinksOnly.map(renderNavLink)}</NavLinks>
                </Transition>
              </LinksCol>
            </HeaderContent>
            <LanguageSelector>
              <LanguageIconMod />
              <Mobile onClick={mobileLanguage}>
                <LanguageName padding={0} active>{lang}</LanguageName><ArrowDownIconMod />
                <LanguageDropDown isOpen={languageDropDownOpen}>
                  <Link to={lang !== 'en' ? secondaryLangUri : removePrefix(location.pathname)}>
                    <LanguageName mobile active={lang === 'en'}>English {lang === 'en' && <MobileIndicator />}</LanguageName>
                  </Link>
                  <HDivider />
                  <Link to={lang !== 'es' ? secondaryLangUri : removePrefix(location.pathname)}>
                    <LanguageName mobile active={lang === 'es'}>Español {lang === 'es' && <MobileIndicator />}</LanguageName>
                  </Link>
                </LanguageDropDown>
              </Mobile>
              <Desktop>
                <Divider />
                <Link to={lang !== 'en' ? secondaryLangUri : removePrefix(location.pathname)}>
                  <LanguageName active={lang === 'en'}>English</LanguageName>
                </Link>
                <Divider />
                <Link to={lang !== 'es' ? secondaryLangUri : removePrefix(location.pathname)}>
                  <LanguageName active={lang === 'es'}>Español</LanguageName>
                </Link>
                <Indicator id="languageIndicator" right={indicatorPosition} />
              </Desktop>
            </LanguageSelector>
          </HeaderContainer>
          <MobileNavLinks visible={globalNavOpen}>
            {headerNavLinks.map(renderMobileNavLink)}
          </MobileNavLinks>
          <Progress pageColors={pageColors} visible={globalNavOpen} />
        </Headroom>
      </HeaderWrapper>
      <RemoveScroll enabled={sidebarVisible}>
        <MobileSidebar>
          <MobileSidebarNavigation
            basePath={location.pathname}
            location={location}
            pageColors={pageColors}
            sidebarVisible={sidebarVisible}
            hideNavigation={hideNavigation}
            includeHeaderLinks={extraLinks}
          />
          <Overlay visible={sidebarVisible} onClick={hideNavigation} />
        </MobileSidebar>
      </RemoveScroll>
      <RemoveScroll enabled={globalNavOpen}>
        <Transition
          in={globalNavOpen}
          timeout={{
            enter: 0,
            exit: navTimeout,
          }}
          unmountOnExit
        >
          <GlobalNavigation
            isOpen={globalNavOpen}
            closeNavigation={toggleGlobalNav}
          />
        </Transition>
      </RemoveScroll>
    </>
  );
}

Header.propTypes = {
  hasHero: PropTypes.bool,
  location: PropTypes.object,
  headerNavLinks: PropTypes.array,
  globalNavLinks: PropTypes.array,
  title: PropTypes.string,
  pageColors: PropTypes.object,
};
